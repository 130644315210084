import { useState } from 'react';
import axios from 'axios';
import { useRouter } from 'next/router';
import { useSelector, useDispatch } from 'react-redux';
import { SvgLikeActive, SvgLikeEmpty } from '../../icons';
import { fetchAll } from '../../redux/modules/favorites';

const AddFavorite = ({data, type, api, ky}) => {
  const router = useRouter();
  const [active, setActive] = useState(true);
  const dispatch = useDispatch();
  const fav = useSelector(state => state.favorites);
  const mobile = useSelector(state => state.mobile);

  if(!fav) return null;

  const flats = fav.response.flats;
  const objects = fav.response.objects;
  const flatsCommerce = fav.response.flatsCommerce;
  const objectsCommerce = fav.response.objectsCommerce;
  const flatsCountry = fav.response.flatsCountry;
  const objectsCountry = fav.response.objectsCountry;

  const getCatalogType = () => {
    if(ky){
      if(fav.response[ky]){
        return fav.response[ky];
      }
    }
    else{
      if(router.query.slug){
        if(router.query.slug[0] === 'catalog_new'){
          if(type == 0){
            return flats;
          }
          else{
            return objects;
          }
        }
        else if(router.query.slug[0] === 'catalog_cre'){
          if(type == 0){
            return flatsCommerce;
          }
          else{
            return objectsCommerce;
          }
        }
        else if(router.query.slug[0] === 'catalog_country'){
          if(type == 0){
            return flatsCountry;
          }
          else{
            return objectsCountry;
          }
        }
      }
    }

    if(type == 0){
      return flats;
    }
    else{
      return objects;
    }
  }

  const animate = e => {
    if(!mobile){
      const element = e.currentTarget;
      let elementAnim = null;
      for(let i = 0 ; i < element.children.length; i++){
        if(element.children[i].tagName === 'B'){
          elementAnim = element.children[i];
        }
      }

      if(elementAnim){
        const elementX = element.getBoundingClientRect().x;
        const elementY = element.getBoundingClientRect().y;
        const icon = document.querySelector('.header__right .fav-icon');
        const iconX = icon.getBoundingClientRect().x;
        const iconY = icon.getBoundingClientRect().y;
        const speed = 800;
        const x = -(elementX - iconX);
        const y = Math.abs(elementY - iconY);

        element.classList.add('active-anim');
        elementAnim.style.opacity = 0;
        elementAnim.style.transition = `${speed}ms transform, ${speed - 100}ms opacity`;
        elementAnim.style.transform = `translateY(-${y}px) translateX(${x}px)`;

        setTimeout(() => {
          element.classList.remove('active-anim');
          elementAnim.removeAttribute('style')
        },speed);
      }
    }
  }

  const toggleFlat = (e, detect) => {
    if(!detect){
      animate(e);
    }
    if(active){
      setActive(false);
      const params = {
        page: detect ? 'favorites-del' : 'favorites-add',
        type: 'flat',
        id: data.id
      }
      axios
      .get(api + '/ajax/json.php', { params })
      .then(({ data }) => data)
      .then(response => {
        dispatch(fetchAll(api));
        setActive(true);
				if(window.ym) ym(4831804,'reachGoal','webit_addTo_favourites'); 
			})
			.catch(function (error) {
				setActive(true);
				if(window.ym) ym(4831804,'reachGoal','webit_addTo_favourites'); 
			})
    }
  }

  const toggleObject = (e, detect) => {
    if(!detect){
      animate(e);
    }
    if(active){
      setActive(false);
      const params = {
        page: detect ? 'favorites-del' : 'favorites-add',
        type: 'object',
        code: data.code
      }

      axios
				.get(api + '/ajax/json.php', { params })
				.then(({ data }) => data)
				.then(response => {
					dispatch(fetchAll(api));
					setActive(true);
					if(window.ym) ym(4831804,'reachGoal','webit_addTo_favourites'); 
				})
				.catch(function (error) {
					setActive(true); 
					if(window.ym) ym(4831804,'reachGoal','webit_addTo_favourites'); 
				})
    }
  }

  if(type == 0){
    const flts = getCatalogType();
    const detect = flts.filter(item => item.id == data.id).length;
    return(
      <div
        className={!!detect ? 'add-favorite active' : 'add-favorite'}
        onClick={e => {
          e.stopPropagation();
          toggleFlat(e, detect)
        }}
      >
        <b><SvgLikeActive /></b>
        <i><SvgLikeActive /></i>
        <SvgLikeEmpty />
      </div>
    )
  }

  const objct = getCatalogType();
  const detect = objct.filter(item => item.code == data.code).length;
  return(
    <div
      className={!!detect ? 'add-favorite active' : 'add-favorite'}
      onClick={e => {
        e.stopPropagation();
        toggleObject(e, detect);
      }}
    >
      <b><SvgLikeActive /></b>
      <i><SvgLikeActive /></i>
      <SvgLikeEmpty />
    </div>
  )
}

export default AddFavorite;
