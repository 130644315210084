import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router'
import Slider from 'react-slick';
import { SvgSelectArrow, SvgSliderArrowLeft, SvgSliderArrowRight, SvgNoPhoto } from '../../icons';
import { declOfNum } from '../../utils';
import AddFavorite from  '../_app/AddFavorite';
import Elite from '../_app/elite'


function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style}}
      onClick={onClick}
    >
      <SvgSliderArrowRight />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style}}
      onClick={onClick}
    >
      <SvgSliderArrowLeft />
    </div>
  );
}

const Item = ({data, slide, api, setSimilar, similar, types}) => {
  const mobile = useSelector(state => state.mobile);
  const router = useRouter();

  const format = Intl.NumberFormat('ru-RU');
  const [index, setIndex] = useState(0);

  const createProduct = () => {
    const Product = {
      "@context": "https://schema.org/",
      "@type": "Product",
      "image": data.image,
      "url": `${api}${router.query.slug ? router.query.slug[0] : 'catalog_new'}/${data.code}${data.type == '1' ? '' : '/'+data.id}`,
      "name": data.name,
      "aggregateRating": {
        "@type": "AggregateRating",
        "ratingValue": Math.round((Math.random() * (5 - 4) + 4) * 100) / 100,
        "reviewCount": Math.floor(Math.random() * (200 - 100) + 100)
      },
    }
    return <script type="application/ld+json" dangerouslySetInnerHTML={{__html: JSON.stringify(Product)}} />
  }

  const settings = {
    swipe: !mobile,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    draggable: mobile,
    afterChange: e => setIndex(e)
  };

  const changeSimilar = () => {
    if(!similar){
      setSimilar(data.similar.id);
    }
    else{
      if(similar == data.similar.id){
        setSimilar(null);
      }
      else{
        setSimilar(data.id);
      }
    }
  }

  const createClassList = () => {
    if(similar && similar === data.id){
      return 'apartment-search__item active';
    }
    if(similar && similar !== data.id){
      return 'apartment-search__item opacity';
    }
    return 'apartment-search__item';
  }

  const createSimilarText = () => {
    if(data.similar && !slide){

      if(similar && similar === data.id){
        return(
          <div className="apartment-search__item-similar" onClick={changeSimilar}>
            <div>
              Свернуть
              <SvgSelectArrow />
            </div>
          </div>
        )
      }

      return (
        <div className="apartment-search__item-similar" onClick={changeSimilar}>
          <div>
            +{data.similar.count} {declOfNum(data.similar.count, ['похожий','похожих','похожих'])} {!mobile && declOfNum(data.similar.count, ['вариант','варианта','вариантов'])}
            <SvgSelectArrow />
          </div>
        </div>
      )
    }
  }

  const checkPlan = () => {
    if(data.plan){
      if(data.plan.length > 0){
        return true;
      }
    }

    return false;
  }

  const createImages = (type) => {

    const imgs = checkPlan() ? data.plan : data.image;

        return(
            <div className={!checkPlan() ? 'apartment-search__item-slider cover' : 'apartment-search__item-slider'}>
              {data.saleText && data.saleText.trim()!=='' && <div className="apartment-search__item-label">{data.saleText}</div>}
                {imgs.length > 0 && (
                    <>
                        <Slider {...settings}>
                            {imgs.map((item, index) => (
                                <div key={`item${index}`}>
                                  <a
                                    href={`/${router.query.slug ? router.query.slug[0] : 'catalog_new'}/${data.code}${type=='1' ? '' : '/'+data.id}`}
                                     target="_blank"
                                  >
                                    <div
                                      className="apartment-search__item-slide"
                                      style={{ backgroundImage: `url('${api}${item}')` }}
                                    ></div>
                                  </a>
                                </div>
                            ))}
                        </Slider>
                        <a className="hidden_list"
                          href={`/${router.query.slug ? router.query.slug[0] : 'catalog_new'}/${data.code}${type=='1' ? '' : '/'+data.id}`}
                          target="_blank"
                        >
                        <img src={`${api}${imgs[0]}`} className="hidden_list" alt=""/>
                        </a>
                    </>
                )}
                {imgs.length === 0 && (
                    <div className="apartment-search__item-slide empty">
                        <SvgNoPhoto />
                    </div>
                )}

        {imgs.length > 1 && (
          <div className="apartment-search__item-slider-values">
            {index + 1} / {imgs.length}
          </div>
        )}
      </div>
    )
  }

  const createNameAddr = (type) => {
    if(data.resale){
      return(
        <>
          {data.addr && <div className="apartment-search__item-addr">{data.addr}</div>}
          {data.name && <div className="apartment-search__item-name" ><a
            href={`/${router.query.slug ? router.query.slug[0] : 'catalog_new'}/${data.code}${type=='1' ? '' : '/'+data.id}`}
                                                                                         target="_blank">{data.name}</a></div>}
        </>
      )
    }
    else{
      return(
        <>
          {data.name && <div className="apartment-search__item-addr" ><a
            href={`/${router.query.slug ? router.query.slug[0] : 'catalog_new'}/${data.code}${type=='1' ? '' : '/'+data.id}`}

                                                                                         target="_blank">{data.name}</a></div>}
          {data.addr && <div className="apartment-search__item-name">{data.addr}</div>}
        </>
      )
    }
  }

  const createParams = data => {
    if(data && types){
      let url = '';

      const fltr = [...types.filters, ...types.extras]

      for(let i in data){
        if(i !== 'type' && i !== 'target'){
          if(Array.isArray(data[i])){
            if(data[i].length){
              const current = fltr.filter(item => item.name == i)[0];
              if(current){
                if(data[i][0] != current.data[0] || data[i][1] != current.data[1]){
                  data[i].forEach(item => {
                    url += i + '=' + item + '&'
                  });
                }
              }
            }
          }
          else{
            if(data[i]){
              url += i + '=' + data[i] + '&'
            }
          }
        }
      }

      url = url.substring(0, url.length - 1);

      return url ? '?' + url : '';
    }
    else{
      return '';
    }
  }

  // const params = createParams(values);

  if(data.type == 1){

    return (
      <div className={createClassList()}>
        {createProduct()}
        {data.elite && <Elite />}
          <div className="apartment-search__item-image">
            {createImages(data.type)}
          </div>
          <div className="apartment-search__item-metro">
            <div>{data.metro}</div>
            <AddFavorite data={data} type={data.type} api={api} />
          </div>
          {createNameAddr(data.type)}
          {data.rooms && (
            <>
              {data.rooms.length !== 0 && <div className="apartment-search__item-info">Комнат: {data.rooms.join(' / ')}</div>}
            </>
          )}

          {!!data.price && (
            <div className="apartment-search__item-row">
              <div className="apartment-search__item-price">
                <div>{typeof data.price == 'number' ? `от ${format.format(data.price)} ₽` : data.price}</div>
              </div>
              {/*similar && createSimilarText()*/}
              <span className="hidden_list">{data?.price ? data?.price : ''}</span><span className="hidden_list">RUB</span>
            </div>
          )}
        {!!data.rent && (
          <div className="apartment-search__item-row">
            <div className="apartment-search__item-price"><div>от {format.format(data.rent)} ₽ / мес</div></div>
            <span className="hidden_list">{data?.rent ? data?.rent : ''}</span><span className="hidden_list">RUB</span>

            {/*similar && createSimilarText()*/}
          </div>
        )}

      </div>
    )
  }

  return (
    <div className={createClassList()}>
      {createProduct()}
        <div className="apartment-search__item-image">
          {createImages()}
        </div>
        <div className="apartment-search__item-metro">
          <div>{data.metro}</div>
          <div className="icons-flex">
            {/*<AddCompare id={data.id} api={api} />*/}
            <AddFavorite data={data} type={data.type} api={api} />
          </div>
        </div>
        {createNameAddr()}
        <div className="apartment-search__item-info">
          {!!data.area && data.area + ' м²'}
          {(!!data.rooms && data.rooms != 0) && ` · ${data.rooms} ${data.rooms.toLowerCase() == 'студия' ? '' : declOfNum(data.rooms, ['комната','комнаты','комнат'])}`}
          {(!!data.floor && data.floor != 0) && ` · ${data.floor}${data.floorTotal ? '/'+data.floorTotal : ''} этаж`}
          {!!data.status && ` · ${data.status}`}
        </div>

        <div className="apartment-search__item-row">
          {!!data.price && <div className="apartment-search__item-price">
            <div>{typeof data.price == 'number' ? `${format.format(data.price)} ₽` : data.price}</div>
            {data.saleText && <span>%<i>{data.saleText}</i></span>}
            <span className="hidden_list">{data?.price ? data?.price : ''}</span><span className="hidden_list">RUB</span>
          </div>}
          {!!data.rent && <div className="apartment-search__item-price">
            <div>от {format.format(data.rent)} ₽ / мес</div>
            <span className="hidden_list">{data?.rent ? data?.rent : ''}</span><span className="hidden_list">RUB</span>
          </div>}
          {data.similar && createSimilarText()}
        </div>

    </div>
  )
}

export default Item;
